import React from 'react'
import { Link } from 'gatsby'
import utils from '../utility/utils'
import Layout from "../components/layout"
import Pagination from "../components/pagination"
import PropTypes from 'prop-types';


export default function Pages({ pageContext, location }) {
  const { posts, page, pagesSum, pageTitle, pagePath } = pageContext;
  
  return (
    <Layout location={location}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <section id="news-list" className="section"  aria-labelledby="region1">
              <div className="row">
                <div className="col-12">
                  <h1 id="region1" className="section__title">{pageTitle}</h1>
                </div>
              </div>
              {/*
              <div className="row">
                <div className="col-12">
                  <div class="news-search">
                    <form action="#" method="get" id="news-search" className="news-search__form">
                      <fieldset className="fieldset">
                        <legend className="visuallyhidden">News search</legend>
                        <div className="row">
                          <div className="col-md-1">
                            <div className="news-search__text"><strong>Filtra</strong></div>
                          </div>
                          
                          <div className="col-md-4">
                            <label for="year" className="visuallyhidden">Anno</label>
                            <div className="news-search__select">
                              <select name="year" id="year">
                                <option value="">Anno</option>
                                <option value="2018">2018</option>
                                <option value="2017">2017</option>
                                <option value="2016">2016</option>
                                <option value="2015">2015</option>
                                <option value="2014">2014</option>
                              </select>
                            </div>
                          </div>
                          
                          <div className="col-md-4">
                            <label for="topic" className="visuallyhidden">Argomento</label>
                            <div className="news-search__select">
                              <select name="topic" id="topic">
                                <option value="">Argomento</option>
                                <option value="Formazione">Formazione</option>
                                <option value="Ricerca">Ricerca</option>
                                <option value="Servizio">Servizio</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <button type="submit" name="submitsearch" value="" className="news-search__submit" title="Cerca">
                              <i className="fa fa-search" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
              */}
              <div className="row">
                {posts.map((item,index) => 
                  <div key={index} className="col-12">
                    <article className="news news--horizontal news--dark clearfix">
                      {!!item.node.immagine &&
                        <figure className="news__figure text-center">
                          <img src={item.node.immagine.file.url} alt="Inaugurazione ospedale" className="news__img" />
                        </figure>
                      }
                      <div className="news__info">
                        <h2 className="news__title">
                          <Link to={"/news-ed-eventi/"+item.node.friendlyUrl} title={item.node.titolo} className="news__link">{item.node.titolo}</Link>
                        </h2>
                        <div className="news__category">{item.node.argomento}</div>
                        {!!item.node.abstractNews &&
                          <div className="news__abstract" dangerouslySetInnerHTML={{ __html: item.node.abstractNews.childMarkdownRemark.html }} >
                          </div>
                        }
                        <span className="news__date">{utils.formatDate(item.node.data)}</span>
                      </div>
                    </article>
                  </div>
                )}
              </div>
              {pagesSum > 1 &&
                <Pagination page={page} pagesSum={pagesSum} pagepath={pagePath} />
              }
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

Pages.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object,
};

